import {graphql} from 'gatsby'
import React from 'react'

import {Layout, MetaData} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'

import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import SupportIcon from '../../../images/inline-svgs/support.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'

const PaperbackGhostTheme = ({data}) => (
  <Layout>
    <MetaData
      title="Paperback – A Ghost Blogging Theme"
      description="A little unconventional, but beautifully designed ghost theme suitable for building blogs, journals, photography blogs, and much more."
    />
    <ThemePageHeader
      title="Paperback"
      eyebrow="Ghost"
      description="A little unconventional, but beautifully designed <a href='https://ghost.org' class='link--hover--underline' target='_blank' rel='noopener noreferrer'>Ghost</a> theme suitable for building blogs, journals, photography blogs, and much more."
      price="$ 30"
      purchaseLink="https://gumroad.com/l/paperback-theme"
      demoLink="https://paperback.stylesheets.dev/"
      image={data.header.childImageSharp.gatsbyImageData}
      eyebrowLink="/themes/ghost/"
    />

    <FeatureCards>
      <ThemeFeatureCard
        title="Ghost 4.x"
        description="Supports latest version of Ghost."
      >
        <GhostIcon fill="#444" width="40px" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Fully Responsive"
        description="Fully responsive layout for best reading experience across all devices."
      >
        <ResponsiveIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Free Support"
        description="Reach out to us and we’ll help you out with whatever issue you are facing."
      >
        <SupportIcon fill="#444" />
      </ThemeFeatureCard>
    </FeatureCards>

    <Features>
      <FeaturesList>
        <li>Supports all basic features of Ghost</li>
        <li>Responsive layout</li>
        <li>Fully customisable typography and color palette</li>
        <li>Infinite loading layout with load more button</li>
        <li>Post sharing icons for Twitter, Linkedin and Email</li>
        <li>
          All major social media icons included in the theme (Facebook, Twitter,
          Instagram, Github, Pinterest, Linkedin, Youtube, Tumblr, Medium,
          Reddit, 500px, Dribbble)
        </li>
        <li>Free and regular updates</li>
        <li>Google fonts</li>
      </FeaturesList>
    </Features>

    <CTA title="Ready to Get Started?">
      <CTAItem
        title="Purchase Theme"
        link="https://gumroad.com/l/paperback-theme"
        externalLink
      >
        <DownloadIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="View Demo"
        link="https://paperback.stylesheets.dev/"
        externalLink
      >
        <ResponsiveIcon fill="#fff" />
      </CTAItem>
    </CTA>
  </Layout>
)

export default PaperbackGhostTheme

export const query = graphql`
  query {
    header: file(relativePath: {eq: "themes/paperback.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
